<template>
  <v-container fluid>
    <editor
      :pageTitle="pageTitle"
      :pageMode="pageMode"
      :authUsers="authUsers"
      :createStatus="createStatus"
      :initial="dataItem"
      @data="save"
    ></editor>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      pageMode: "create",
      pageTitle: "Create Approval Stage",
      approvals: [],
      authUsers: [],
      dataItem: {},
      createStatus: true,
      selectedApproval: {},
      userHeaders: [{ text: "Name", value: "users.name" }],
    };
  },
  methods: {
    save(data) {
      const url = `/approval_stages`;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push("/approval-stages");
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
