<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/approval-stages">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        v-show="pageMode == 'create'"
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="$store.state.loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
      <v-btn
        v-show="pageMode == 'view'"
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon> Update
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="record.Name"
                  label="Stage Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="record.Remarks"
                  label="Stage Description"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="record.MaxReqr"
                  label="No. of Approvals Required"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="record.MaxRejReqr"
                  label="No. of Rejections Required"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="5">
            <v-row dense>
              <v-col cols="12">
                <v-btn color="flatButton" text @click="openModal">
                  <v-icon>mdi-plus</v-icon>Add Authorizer
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="authorizers"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <!-- user dialog -->
          <v-dialog v-model="userDialog" max-width="550px">
            <v-card>
              <v-toolbar color="primary" dense dark>
                <v-toolbar-title>Select Authorizer</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="userDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      @change="addUser"
                      dense
                      outlined
                      v-model="newUser"
                      :items="users"
                      label="Authorizers"
                      item-text="name"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- user dialog -->

          <snackbar ref="snackbar"></snackbar>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
    createStatus: {
      type: Boolean,
    },
    authUsers: {
      type: Array,
    },
    pageMode: {
      type: String,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      record: {},
      loader: false,
      status: false,
      userDialog: false,
      newUser: null,
      dialog: false,
      users: [],
      authorizers: [],
      headers: [
        { text: "Authorizer", value: "name" },
        { text: "Department", value: "department" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
    },
    authUsers: {
      handler: "setAuthorizers",
      immediate: true,
    },
    createStatus: {
      handler: "setStatus",
      immediate: true,
    },
  },

  methods: {
    setAuthorizers(val) {
      this.authorizers = [...val];
    },
    setInitial(val) {
      this.record = { ...val };
    },
    setStatus(val) {
      this.status = val;
    },
    openModal() {
      this.newUser = false;
      this.userDialog = true;
    },
    addUser() {
      const id = this.newUser;
      const user = this.authorizers.find((record) => {
        return record.id === id;
      });

      const addedUser = this.users.find((record) => {
        return record.id === id;
      });
      if (user == undefined) {
        this.authorizers.push(addedUser);
        this.newUser = null;
        this.$refs.snackbar.show(`${addedUser.name} added`, "green");
        this.userDialog = false;
      } else {
        this.$refs.snackbar.show(
          `${user.name} already exist in the authorizers list`,
          "red"
        );
        this.newUser = null;
      }
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      const users = this.authorizers;
      let ids = users.reduce((op, { id }) => {
        if (typeof id !== "undefined") {
          op.push(id);
        }
        return op;
      }, []);

      const data = this.record;
      data.users = ids;
      this.$emit("data", data);
      this.$store.commit("loader", true);
      setTimeout(() => {
        this.$store.commit("loader", false);
      }, 500);
    },
    updateData() {},
  },
  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped></style>
